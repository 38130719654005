var $ = require('jquery');
var _ = require('lodash');
var AriesBase = require('./aries-base');
var PubSub = require('./pub-sub');
var PostMessageHandler = require('./post-message-handler');
var PubSubMessageKeys = require('utils/pubSubMessageKeys');
var Cookies = require('./cookies');
var handlebars = require('handlebars');
var Promise = require('bluebird');
var Constants = require('libs/constants');
// Serialize Object
require('./serialize-object');

/**
 * Aries Component base implementation
 */
var AriesComponent = AriesBase.extend({
  // contains the VDOM element for client and body element for server
  $el: null,
  data: {},
  pubSubMessageKeys: PubSubMessageKeys,
  _eval: function (template) {
    var ariesModule = { exports: {} };
    (function () {
      var exports = {};
      try {
        eval(template);
      } catch (ex) {
        throw new Error('Exception evaluating remote code \'' + template + '\'');
      }
      if (!$.isEmptyObject(exports) && $.isEmptyObject(ariesModule.exports)) {
        ariesModule.exports = exports;
      }
    })();

    return ariesModule.exports;
  },
  /**
   * XHR call with defaults
   * @param  {Object} params XHR config parameters
   */
  makeAjaxCall: function (params) {
    var _self = this;
    if (!params.url) {
      return console.error('URL is missing');
    }
    var request = {};
    request.method = 'get';
    request.dataType = 'json';
    request.contentType = 'application/json';
    request.cache = false;
    if (window.makenComponents.ACCEPT_LANGUAGE !== null) {
      request.headers = {
        'Accept-Language': window.makenComponents.ACCEPT_LANGUAGE
      }
      //Tile specific translation for Legacy Sites Start
      try {
        if (JSON.parse(params.data).context.isRealTimeTranslated.includes(window.makenComponents.ACCEPT_LANGUAGE)) {
            request.headers['X-Language-Realtime'] = 'true';
        }
      }
      catch(err) {
      }
      //Tile specific translation for Legacy Sites End
    }
    // adding X-CUSTOM-LOCALE-DIR & X-CUSTOM-LOCALE-ID to request headers for consumption by the play app
    if (window.makenComponents.CUSTOM_LOCALE_DIR !== null) {
      request.headers['X-CUSTOM-LOCALE-DIR'] = window.makenComponents.CUSTOM_LOCALE_DIR;
    }
    if (window.makenComponents.CUSTOM_LOCALE_ID !== null) {
      request.headers['X-CUSTOM-LOCALE-ID'] = window.makenComponents.CUSTOM_LOCALE_ID;
    }
    $.extend(request, params);

    request.success = function (endpointResponse) {
      // code to redirect the page to next URI if nexturi preset in the endpointResponse
      if (!params.skipRedirect && endpointResponse.nextStateURI) {
        window.location.href = endpointResponse.nextStateURI;
      }
      //For associate user remove the global disable class
      $('.is-global-disable').removeClass('is-global-disable');
      if (_.isFunction(params.success)) {
        params.success(endpointResponse);
      }
    };

    request.error = function (endpointResponse) {
      //For associate user remove the global disable class
      $('.is-global-disable').removeClass('is-global-disable');
      if (_.isFunction(params.error)) {
        params.error(endpointResponse);
      }
    };
    return $.ajax(request);
  },

  /**
   * Serialize form fields
   * @param {HTMLNode} target Form element
   * @returns {Object} Serialized data
   */
  serializeFormData: function (target) {
    return $(target).serializeObject();
  },

  /**
   * Method to return current page URI
   * @returns {String} Current page pathname
   */
  getSourceURI: function () {
    return window.location.pathname;
  },

  /**
   * Method to return the current variation of the component
   * @returns {String} Variation number
   */
  getVariation: function () {
    return this.context.variation;
  },

  /**
   * Method to return the global context data
   * @returns {Object} Global context data
   */
  getContextData: function () {
    var componentContext = this.context;
    var context = {
      localeKey: componentContext.context.localeKey,
      siteId: componentContext.context.siteId,
      siteName: componentContext.context.siteName
    };

    // TODO: modify to send all context data
    if (typeof componentContext.context.marshaCode !== 'undefined') {
      context.marshaCode = componentContext.context.marshaCode;
    }

    if (this.contextToSend) {
      context[this.contextToSend.name] = this.contextToSend.data;
      delete this.contextToSend;
    }

    return context;
  },

  /**
   * Method to return the component context with additional data for component refresh calls or any custom Ajax which requires the same
   * @param {Object} additionalContextData Data to be added to current context object
   * @returns {Object} New modified context object
   */
  getExtendedComponentContext: function (additionalContextData) {
    var context = _.cloneDeep(this.context.context);

    if (_.isObject(additionalContextData)) {
      _.extend(context, additionalContextData);
    }

    return context;
  },

  /**
   * Method to return the current session ID
   * @returns {String} Session ID
   */
  getSessionToken: function () {
    //return this.context.sessionToken;
    return this._sessionToken;
  },

  /**
   * Update the DOM of the component
   *
   * @param {String} componentID
   * @param {Object} response
   * @param {String} renderType
   */
  update: function (componentID, response, renderType) {
    'use strict';
    var renderOptions = {
      id: componentID,
      data: response.data,
      context: response.context,
      endpoint: response.endpoint,
      name: response.name,
      template: response.template,
      type: renderType
    };

    return this.render(renderOptions);
  },

  /**
   * Object which consists of pubsub generic methods
   */
  pubsub: {
    publish: function (event, data) {
      PubSub.publishGlobal(PubSubMessageKeys[event], data);
      PostMessageHandler.publishFrame(PubSubMessageKeys[event], data);
    },
    unsubscribe: function (type, componentId, event) {
      PubSub.unSubscribeEvent(type, componentId, PubSubMessageKeys[event]);
    }
  },

  /**
   * Client-side refresh of rendered component
   */
  refresh: function (additionalContextData, successCallback, errorCallback) {
    'use strict';

    // TBD: Temporary fix to refresh tile only if this.$el is not null
    // Permanent fix is to remove stale components instances on popupJs close event
    if (!this.$el.length) {
      return;
    }
    var compEndpoint = this.$el.data('component-endpoint');
    var compID = this.$el.data('component-id');

    var data = {};
    var _self = this;

    if (typeof _self.beforeComponentRefresh === 'function') {
      _self.beforeComponentRefresh(additionalContextData);
    }

    data.context = this.getExtendedComponentContext(additionalContextData);
    data.sessionToken = this.getSessionToken();
    data.sourceURI = this.getSourceURI();
    data.variation = this.getVariation();

    this.makeAjaxCall({
      url: compEndpoint,
      method: 'POST',
      data: JSON.stringify(data)
    }).then(function (response) {
      response.component.endpoint = compEndpoint;
      response.component.context = data;
      _self.update(compID, response.component, 'refresh').then(function(){
         // unbind event bindings from global jQuery instance
      if (_self.unBindEvents && window.$) {
        _self.unBindEvents(window.$);
      }

      // Subscribe to global PubSub and DOM events
      _self.subscribePubSubMessages();
      _self.subscribeDOMEvents();

      // bind events - starting point for the component code execution
      if (_self.bindEvents) {
        // Send the global jQuery object to bindEvents
        _self.bindEvents(window.$);
      }

      if (typeof successCallback === 'function') {
        successCallback(additionalContextData);
      } else if (typeof _self.afterComponentRefresh === 'function') {
        _self.afterComponentRefresh(additionalContextData);
      }
      });
     
    }, function (error) {
      if (typeof errorCallback === 'function') {
        errorCallback(error);
      }
    });
  },

  /**
   * Initialize component object with the props
   * @param {Object} props
   */
  initialize: function (props) {
    'use strict';
    this.props = props;
    this._sessionToken = Cookies.readCookie('sessionID');
  },

  /**
   * Subscribe to render event for client-side rendering and refresh
   * @param {Object} component
   */
  render: function (component) {
    'use strict';
    var elementId = null;

    if (component.data) {
      this.data = component.data;
    }

    if (component.context) {
      this.context = component.context;
    }
    var self = this;
    var template;
    var templateName = component.template;
    //if 'template' property doesn't exist on aries object, make ajax call over nginx to fetch the template
    if(!this.template) {
      return $.ajax({
        url: '/aries/'+window.makenComponents.BUILD_TIMESTAMP_VAL+'/components/'+templateName+'/'+templateName+'.hb.js',
        dataType: 'text',
        success: function (result) {
          template = self._eval(result);
          self.compileTemplate(component, template);
        },
        error: function (xhr, t, e) {
          console.log(e.toString());
          template = function () {
            return '<span style="color: red;">' +
              'Error: <strong>Template not available</strong>' +
              '</span>';
          };
          self.compileTemplate(component, template);
        }
      });
    } 
    else{
      return new Promise(function(resolve, reject){
        template = self.template[templateName];
        if(typeof template === 'undefined'){
          template = function() {
            return '<span style="color: red;">' +
            'Error: <strong>Template not available</strong>' +
            '</span>';
          };
        }
        self.compileTemplate(component, template);
        resolve();
      })    
    } 
  },

  /**
   * Set the element DOM and store for future reference
   * @param {String} id Component ID
   * @param {String} template Template name for current variation
   */
  setElementAndBindEvents: function (id, template) {
    'use strict';
    var _self = this;

    _self.componentId = id;

    if (!_self.$el) {
      _self.$el = $('[data-component-id=' + id + ']');
    }

    if (!_self.context) {
      _self.context = makenComponents.resolvedComponentContexts[_self.type][id];
    }

    _self.currentTemplate = template;

    // unbind events
    if (_self.unBindEvents && window.$) {
      _self.unBindEvents(window.$);
    }

    // Subscribe to global PubSub and DOM events
    _self.subscribePubSubMessages();
    _self.subscribeDOMEvents();

    // bind events
    if (_self.bindEvents) {
      _self.bindEvents(window.$);
    }
  },

  /**
   * Subscribe to global PubSub events
   */
  subscribePubSubMessages: function () {
    var _self = this;

    if (_self.subscribe) {
      _.each(_self.subscribe, function (callback, messages) {
        _.each(messages.split('|'), function (event) {
          var subscribeId = _self.$el ? _self.$el.data('subscribe-id') || _self.$el.data('component-id') : '';
          PubSub.subscribeGlobal({
            type: _self.type,
            id: subscribeId,
            event: PubSubMessageKeys[event],
            callback: _.bind(_self[callback], _self)
          });
        });
      });
    }
  },

  /**
   * Subscribe to component DOM events with aries namespace. Has dependency that the component should have a wrapper/root element.
   */
  subscribeDOMEvents: function () {
    var _self = this;
    var event, selector, spacePos;

    _self.unsubscribeDOMEvents();
    if (_self.events) {
      _.each(_self.events, function (callback, eventString) {
        spacePos = eventString.indexOf(' ');
        event = eventString.substr(0, spacePos);
        selector = eventString.substr(spacePos + 1);

        // Sub-component implementations which want to override $el can set -
        // elOverride: true
        var componentRoot = _self.elOverride ? _self.$el : $('[data-component-id=' + _self.componentId + ']');
        componentRoot.on(event + '.ariesEvents' + _self.componentId, selector, _.bind(_self[callback], _self));
      });
    }
  },

  /**
   * Unsubscribe to component DOM events
   * in the ariesEvents namespace
   */
  unsubscribeDOMEvents: function () {
    $('[data-component-id=' + this.componentId + ']').off('.ariesEvents' + this.componentId);
  },

  /**
   * refreshDom method
   *
   * @param  {Object} component config details
   */
  refreshDom: function (component) {
    'use strict';
    var elSelector = '[data-component-id=' + component.id + ']';
    var element = this.$el.children().first();
    element.attr('data-component-id', component.id);
    element.attr('data-component-name', component.name);
    element.attr('data-component-endpoint', component.endpoint);

    $(elSelector).replaceWith(this.$el.html());
    this.$el = $(elSelector);
  },

  /**
   * appendToDom method
   *
   * @param  {Object} component config details
   */
  appendToDom: function (component) {
    'use strict';
    var elSelector = '#' + component.id;
    var elHtml = this.$el.html();
    if (typeof elHtml === 'string' && elHtml.trim() !== '') {
      $(elSelector).closest('.mi-sub-section').removeClass('mi-sub-section-blank');
    }

    $(elSelector).replaceWith(elHtml);
    this.$el = $(elSelector);

    PubSub.publishEvent(this.type, 'init', [component.id, component.template]);

    if (typeof makenComponents !== 'undefined') {
      if (typeof makenComponents.resolvedComponentIDs[this.type] === 'undefined') {
        makenComponents.resolvedComponentIDs[this.type] = [];
      }
      makenComponents.resolvedComponentIDs[this.type].push(component.id);
    }
  },
  /**
   * compileTemplate method
   *
   * @param  {Object} component config details
   */
  compileTemplate: function (component, template) {
    'use strict';
    var self = this;
    self.$el = $('<div></div>');
    self.$el.html(template(component.data));

    /**
     * TODO: Should be ideally having a root node with data-component-id
     */
    if (typeof component.id !== 'undefined') {
      var compElement = self.$el.children().first();
      compElement.attr('data-component-id', component.id);
      compElement.attr('data-component-name', component.name);
      compElement.attr('data-component-endpoint', component.endpoint);
    }
    if (component.type === 'refresh') {
      self.refreshDom(component);
    } else {
      self.appendToDom(component);
    }
    var elementId = self.$el.data('component-id');
    if (typeof window.componentInstances[self.type] === 'undefined') {
      window.componentInstances[self.type] = {};
    }
    window.componentInstances[self.type][elementId] = self;

    if (typeof window.makenComponents.clientResolvedIDs === 'undefined') {
      window.makenComponents.clientResolvedIDs = [];
    }
    window.makenComponents.clientResolvedIDs.push(elementId);
  }
});

module.exports = AriesComponent;
