var _ = require('lodash');
var PubSub = require('./pub-sub');

var AriesBase = function() {
  if (this.initialize) {
    this.initialize.apply(this, arguments);
  }
};

AriesBase.extend = function(protoProps, staticProps) {
  var parent = this;
  var child = (protoProps && _.has(protoProps, 'constructor')) ?
    protoProps.constructor :
    function() {
      return parent.apply(this, arguments);
    };

  _.extend(child, parent, staticProps);

  var Surrogate = function() {
    this.constructor = child;
  };
  Surrogate.prototype = parent.prototype;
  child.prototype = new Surrogate();

  if (protoProps) {
    _.extend(child.prototype, protoProps);
  }

  child.__super__ = parent.prototype;
  return child;
};

AriesBase.create = function(componentProps) {
  PubSub.register(this.extend(componentProps));
};

module.exports = AriesBase;
