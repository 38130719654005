var jquery = require('jquery');
var lodash = require('lodash');
var jqueryUi = require('jquery-ui');
var magnificPopup = require('magnific-popup');
var pubsub = require('pubsub.js');
var lazyloader = require('lazyloader');
var GoogleApiWrapper = require('js-google-api-wrapper');
var CurrencySymbolMap = require('currency-symbol-map');
var JqueryScrollbar = require ('jquery.scrollbar');
var LocalStorageFallback = require('local-storage-fallback');
var MarkerClustererPlus = require('marker-clusterer-plus');
var Markerwithlabel = require('markerwithlabel');
var RandomSeed = require('random-seed');
var ResizePolyfill = require('resize-polyfill');
var Scrollmagic = require('scrollmagic');
var Validator = require('validator');
var Extend = require('extend');
var Bluebird = require('bluebird');

var AriesBase = require('./libs/aries-base');
var AriesComponent = require('./libs/aries-component');
var Popup = require('./libs/popup');
var PubSub = require('./libs/pub-sub');
var PubSubMessageKeys = require('./utils/pubSubMessageKeys');
var cookies = require('./libs/cookies');

exports.jquery = jquery;
exports.lodash = lodash;
exports.jqueryUi = jqueryUi;
exports.magnificPopup = magnificPopup;
exports.lazyloader = lazyloader;
exports.pubsub = pubsub;

exports.AriesBase = AriesBase;
exports.AriesComponent = AriesComponent;
exports.Popup = Popup;
exports.PubSub = PubSub;
exports.PubSubMessageKeys = PubSubMessageKeys;
exports.cookies = cookies;
exports.GoogleApiWrapper = GoogleApiWrapper;
exports.CurrencySymbolMap = CurrencySymbolMap;
exports.JqueryScrollbar = JqueryScrollbar;
exports.LocalStorageFallback = LocalStorageFallback;
exports.MarkerClustererPlus = MarkerClustererPlus;
exports.Markerwithlabel = Markerwithlabel;
exports.RandomSeed = RandomSeed;
exports.ResizePolyfill = ResizePolyfill;
exports.Scrollmagic = Scrollmagic;
exports.Validator = Validator;
exports.Extend = Extend;
exports.Bluebird = Bluebird;
